<template>
  <div class="service-times-presentations template-2">
    <page-header class="mx-lg">
      <h1>{{ title }}</h1>
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-header>
    <!-- / Page Header -->

    <page-body class="mx-lg">
      <section class="section-1">
        <div class="bg-block p-4">
          <header class="mb-5">
            <h2>{{ translations.tcAddresses }}</h2>
            <ToggleArrow v-if="toggle == true" @click="Section1 = !Section1" section="Section1" :status="Section1">
            </ToggleArrow>
          </header>
          <b-button v-if="Section1 && (okToEditAddress || okToEditProfile)" variant="primary" @click="addNewAddress()">
            {{ translations.tcAddAddress }}
          </b-button>
          <div v-if="Section1 && translations.components" class="pres-dates d-flex flex-wrap">
            <ServiceCard v-for="(address, index) in individualCommunicationsData.addresses"
              :i18n="translations.components" :key="index" :obj="address" :okToEdit="okToEditAddress || okToEditProfile"
              :okToDelete="okToEditAddress || okToEditProfile"
              :isCandidate="['nonmember', 'prospect'].includes(pageCode)" :ignore="{ edit: true, delete: true }"
              :cardCount="individualCommunicationsData.addresses.length"
              :secure_delete_keys="serviceCardAddressDeleteKeys" :secure_edit_keys="serviceCardAddressEditKeys"
              @delete_approved="onDeleteClick($event)" @edit_requested="onEditClick($event)" class="event mb-3">
            </ServiceCard>
          </div>
        </div>
      </section>
      <section class="section-2">
        <div class="bg-block p-4">
          <header class="mb-5">
            <h2>{{ translations.tcCommunications }}</h2>
            <ToggleArrow v-if="toggle" @click="Section2 = !Section2" section="Section2" :status="Section2">
            </ToggleArrow>
          </header>
          <b-button class="mr-2" variant="primary" v-if="Section2 && (okToEditPhoneFax || okToEditProfile)"
            @click="addPhoneFax()">
            {{ translations.tcAddPhoneOrFax }}
          </b-button>
          <b-button variant="primary" v-if="Section2 && (okToEditEmail || okToEditProfile)"
            @click="emailStoreAndRedirect({})">{{ translations.tcAddEmail }}</b-button>
          <div v-if="Section2 && translations.components" class="pres-dates d-flex flex-wrap">
            <ServiceCard v-for="(phone, index) in individualCommunicationsData.phones" :i18n="translations.components"
              :key="index" :obj="phone" :okToEdit="okToEditPhoneFax || okToEditProfile"
              :okToDelete="okToEditPhoneFax || okToEditProfile" :secure_edit_keys="serviceCardPhoneFaxEditKeys"
              :secure_delete_keys="serviceCardPhoneFaxDeleteKeys" @delete_approved="onDeleteClick($event)"
              @edit_requested="onEditClick($event)" class="event mb-3">
            </ServiceCard>
            <ServiceCard v-for="(email, index) in individualCommunicationsData.emails" :i18n="translations.components"
              :key="index + 50" :obj="email" :okToEdit="okToEditEmail || okToEditProfile"
              :okToDelete="okToEditEmail || okToEditProfile" :secure_edit_keys="serviceCardEmailEditKeys"
              :secure_delete_keys="serviceCardEmailDeleteKeys" @delete_approved="onDeleteClick($event)"
              @edit_requested="onEditClick($event)" class="event mb-3">
            </ServiceCard>
          </div>
        </div>
      </section>
    </page-body>
    <!-- / Page Body -->
    <page-footer class="mx-lg">
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BackJump from '@/components/back-jump/BackJump.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { securityMixin } from '@/mixins/securityMixin'
import ServiceCard from '@/components/service-card/ServiceCard.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'communications',
  mixins: [securityMixin, translationMixin],
  data() {
    return {
      translations: {},
      okToEditAddress: false,
      okToEditPhoneFax: false,
      okToEditEmail: false,
      serviceCardAddressDeleteKeys: {},
      serviceCardAddressEditKeys: {},
      serviceCardPhoneFaxDeleteKeys: {},
      serviceCardPhoneFaxEditKeys: {},
      serviceCardEmailDeleteKeys: {},
      serviceCardEmailEditKeys: {},
      routeInfo: {},
      secured_controls: {
        add_member_profile_email_button: '0afdb576-f724-4fb8-8035-f93f3c3dd8c9',
        add_member_profile_address_button: 'eadf3cbe-cf6b-4d54-ad7d-ed77023ca5df',
        add_member_profile_phone_fax_button: '181f3be2-698b-44ae-82a3-be7009e5c2b2',
        add_non_member_profile_email_button: '365faa6f-e1bf-42dc-9c71-f53dd39fefcd',
        add_non_member_profile_address_button: '48304515-1224-44c5-b33d-a7a194d14599',
        add_non_member_profile_phone_fax_button: '5ed7d54f-a733-40d9-af2f-648af3f05df4',
        delete_member_profile_email_button: '9f4a4f8e-81f7-47b7-a744-310689e205ed',
        delete_member_profile_address_button: '815f03b0-58d9-4edf-9f64-ac586e7f6d62',
        delete_member_profile_phone_fax_button: '6e464bb1-ac03-414b-9288-c841e9315dfa',
        delete_non_member_profile_email_button: 'b73d7f58-92b4-4ec2-8a66-510be2c5d99d',
        delete_non_member_profile_address_button: 'd1435ab5-8647-46f0-989b-cb84b781c2c8',
        delete_non_member_profile_phone_fax_button: '2080fcde-f46c-4f70-aefe-4663d747f76f',
        edit_member_profile_email_button: '62fe7db8-9ddb-4dfb-96d8-eca6395215ce',
        edit_member_profile_address_button: '3630055e-a01b-43c8-ad6c-19312275cfde',
        edit_member_profile_phone_fax_button: '4063ca64-a638-4c1a-8413-28178a60f9c9',
        edit_non_member_profile_email_button: '57a14903-965d-4d77-9b80-d55a29f86dc5',
        edit_non_member_profile_address_button: 'e276cbe8-1cdb-49b0-8d9b-d7f53a1242f3',
        edit_non_member_profile_phone_fax_button: '9ff028ba-de99-48de-9450-e546ce56934d',
      },
      destination: {},
      payload: {},
      toggle: true,
      Section1: true,
      Section2: true,
      backJumpObj: {
        link: '#',
        location: '',
      },
    }
  },
  methods: {
    ...mapActions({
      getControlPermissionsFromList: 'user/getControlPermissionsFromList',
      getIndividualCommunications: 'membership/getIndividualCommunications',
      loadIndividualProfile: 'membership/loadIndividualProfile',
      saveAddress: 'membership/saveAddress',
      saveEmail: 'membership/saveEmail',
      savePhone: 'membership/savePhone',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedAddress: 'user/setSelectedAddress',
      setSelectedEmail: 'user/setSelectedEmail',
      setSelectedPhone: 'user/setSelectedPhone',
    }),
    createTitle(code) {
      return {
        member: this.translations.tcMemberCommunications,
        prospect: this.translations.tcCandidateCommunications,
        nonmember: this.translations.tcNonMemberCommunications,
      }[code || 'member']
    },
    async pageLoad() {
      this.routeInfo = this.$route
      await Promise.all([
        await this.loadIndividualProfile(),
        await this.getIndividualCommunications(this.userSelectedIndividualKey),
        await this.checkPermissions(),
      ]).then(() => {
        this.backJumpObj.location = this.individualProfile.formal_name
        this.backJumpObj.link = this.backLink
      })
    },
    async checkPermissions() {
      await this.getControlPermissionsFromList(Object.values(this.secured_controls)).then(() => {
        this.okToEditAddress = ['nonmember', 'prospect'].includes(this.pageCode)
          ? this.determineAccessibility(this.secured_controls.edit_non_member_profile_address_button, false)
          : this.determineAccessibility(this.secured_controls.edit_member_profile_address_button, true)

        this.okToEditPhoneFax = ['nonmember', 'prospect'].includes(this.pageCode)
          ? this.determineAccessibility(this.secured_controls.edit_non_member_profile_phone_fax_button, false)
          : this.determineAccessibility(this.secured_controls.edit_member_profile_phone_fax_button, true)

        this.okToEditEmail = ['nonmember', 'prospect'].includes(this.pageCode)
          ? this.determineAccessibility(this.secured_controls.edit_non_member_profile_email_button, false)
          : this.determineAccessibility(this.secured_controls.edit_member_profile_email_button, true)

        if (this.okToEditAddress) {
          this.serviceCardAddressDeleteKeys = ['nonmember', 'prospect'].includes(this.pageCode)
            ? { x: this.secured_controls.delete_non_member_profile_address_button }
            : { y: this.secured_controls.delete_member_profile_address_button }
          this.serviceCardAddressEditKeys = ['nonmember', 'prospect'].includes(this.pageCode)
            ? { x: this.secured_controls.edit_non_member_profile_address_button }
            : { y: this.secured_controls.edit_member_profile_address_button }
        }

        if (this.okToEditPhoneFax) {
          this.serviceCardPhoneFaxDeleteKeys = ['nonmember', 'prospect'].includes(this.pageCode)
            ? { x: this.secured_controls.delete_non_member_profile_phone_fax_button }
            : { y: this.secured_controls.delete_member_profile_phone_fax_button }
          this.serviceCardPhoneFaxEditKeys = ['nonmember', 'prospect'].includes(this.pageCode)
            ? { x: this.secured_controls.edit_non_member_profile_phone_fax_button }
            : { y: this.secured_controls.edit_member_profile_phone_fax_button }
        }

        if (this.okToEditEmail) {
          this.serviceCardEmailDeleteKeys = ['nonmember', 'prospect'].includes(this.pageCode)
            ? { x: this.secured_controls.delete_non_member_profile_email_button }
            : { y: this.secured_controls.delete_member_profile_email_button }
          this.serviceCardEmailEditKeys = ['nonmember', 'prospect'].includes(this.pageCode)
            ? { x: this.secured_controls.edit_non_member_profile_email_button }
            : { y: this.secured_controls.edit_member_profile_email_button }
        }
      })
    },
    async addNewAddress() {
      let newAddress = {
        cnt_key: this.individualProfile.camp_cnt_key
          ? this.individualProfile.camp_cnt_key
          : this.individualProfile.prospect_camp_cnt_key,
      }
      this.addressStoreAndRedirect(newAddress)
    },
    async addressStoreAndRedirect(data) {
      this.setSelectedAddress(data)
      switch (this.pageCode) {
        case 'prospect':
          this.destination.name = 'add-candidate-address'
          break
        case 'nonmember':
          this.destination.name = 'add-nonmember-address'
          break
        default:
          //member
          this.destination.name = 'add-member-address'
          break
      }
      this.$router.push(this.destination)
    },
    async emailStoreAndRedirect(data) {
      this.setSelectedEmail(data)
      switch (this.pageCode) {
        case 'prospect':
          this.destination.name = 'add-candidate-email'
          break
        case 'nonmember':
          this.destination.name = 'add-nonmember-email'
          break
        default:
          //member
          this.destination.name = 'add-member-email'
          break
      }
      this.$router.push(this.destination)
    },
    async addPhoneFax() {
      let newPhone = {
        cnt_key: this.individualProfile.camp_cnt_key
          ? this.individualProfile.camp_cnt_key
          : this.individualProfile.prospect_camp_cnt_key,
      }
      this.phoneStoreAndRedirect(newPhone)
    },
    async phoneStoreAndRedirect(data) {
      this.setSelectedPhone(data)
      switch (this.pageCode) {
        case 'prospect':
          this.destination.name = 'add-candidate-phone'
          break
        case 'nonmember':
          this.destination.name = 'add-nonmember-phone'
          break
        default:
          //member
          this.destination.name = 'add-member-phone'
          break
      }
      this.$router.push(this.destination)
    },
    async addressDelete(data) {
      try {
        this.payload = {
          member_no: this.demograph.member_number,
          adr_key: data.adr_key,
          adr_line1: data.address_line1,
          adr_line2: data.address_line2,
          adr_line3: data.address_line3,
          adr_city: data.city,
          adr_state: data.state,
          adr_postal_code: data.postal_code,
          adr_cnt_key: data.cnt_key,
          adr_business_name: '',
          adr_business_attn: '',
          adr_nag_key: '',
          adr_add_user: this.userId,
          adr_add_date: new Date(),
          adr_change_user: this.userId,
          adr_change_date: new Date(),
          adr_delete_flag: true,
          adr_usps_checked_flag: false,
          adr_usps_valid_flag: false,
          adr_usps_corrected_flag: false,
          adr_usps_uncorrected: '',
          axe_key: data.axe_key,
          axe_entity_key: this.individualProfile.ind_key,
          axe_adt_key: data.adt_key,
          allow_confirm_primary_procedure_run: true,
          primary_address_flag: data.primary_address_flag,
        }
        let result = true
        await Promise.all([this.setLoadingStatus(true), (result = await this.saveAddress(this.payload))]).then(() => {
          this.pageLoad()
          this.$swal({
            icon: result ? 'success' : 'error',
            text: result ? this.translations.tcAddressWasDeleted : this.translations.tcErrorDuringSaveAddressNotSaved,
            confirmButtonText: this.translations.tcOk || 'Ok',
          }).then((result) => {
            if (result.value) {
              this.setSelectedAddress({})
            }
          })
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async phoneDelete(data) {
      try {
        this.payload = {
          member_no: this.demograph.member_number,
          category: data.IsPhone ? 'phone' : 'fax',
          phn_key: data.phn_key,
          phn_number: data.phone_number,
          nag_key: '',
          phn_user: this.userId,
          phn_delete_flag: true,
          phn_cnt_key: data.cnt_key,
          pxe_key: data.pxe_key,
          pxe_extension: data.extension,
          pxe_entity_key: this.individualProfile.ind_key,
          pxe_pht_key: data.pht_key,
          pxe_unlisted_flag: !!data.pxe_unlisted_flag ? data.pxe_unlisted_flag : false,
          pxe_user: this.userId,
          primary_phone_flag: data.primary_phone_flag,
          allow_confirm_primary_procedure_run: true,
        }
        let result = true
        await Promise.all([this.setLoadingStatus(true), (result = await this.savePhone(this.payload))]).then(() => {
          this.pageLoad()
          this.$swal({
            icon: result ? 'success' : 'error',
            text: result ? this.categoryDeleted : this.categoryNotDeleted,
            confirmButtonText: this.translations.tcOk || 'Ok',
          }).then((result) => {
            if (result.value) {
              this.setSelectedPhone({})
            }
          })
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async emailDelete(data) {
      try {
        this.payload = {
          member_no: this.demograph.member_number,
          // email: {
          eml_key: data.eml_key,
          eml_entity_key: this.individualProfile.ind_key,
          eml_emt_key: data.emt_key,
          eml_invalid_flag: data.email_invalid_flag,
          eml_unlisted_flag: !!data.email_unlisted ? data.email_unlisted : false,
          eml_address: data.email_address,
          eml_delete_flag: true,
          eml_user: this.userId,
          // },
          primary_email_flag: data.primary_email_flag,
          allow_confirm_primary_procedure_run: true,
        }
        let result = true
        await Promise.all([this.setLoadingStatus(true), (result = await this.saveEmail(this.payload))]).then(() => {
          this.pageLoad()
          this.$swal({
            icon: result ? 'success' : 'error',
            text: result ? this.translations.tcEmailDeleted : this.translations.tcErrorEmailNotDeleted,
            confirmButtonText: this.translations.tcOk || 'Ok',
          }).then((result) => {
            if (result.value) {
              this.setSelectedEmail({})
            }
          })
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    onDeleteClick(obj) {
      switch (obj.item_description) {
        case 'address':
          if (
            this.createTitle(this.pageCode) === 'member' &&
            this.individualCommunicationsData.addresses.length === 1
          ) {
            this.$swal({
              icon: 'error',
              text: this.translations.tcErrorAddressCannotBeDeleted,
              confirmButtonText: this.translations.tcOk || 'Ok',
            }).then(() => {
              return
            })
          } else {
            this.addressDelete(obj)
          }
          break
        case 'phone number':
          this.phoneDelete(obj)
          break
        default:
          //email
          this.emailDelete(obj)
          break
      }
    },
    onEditClick(obj) {
      switch (obj.item_description) {
        case 'address':
          this.addressStoreAndRedirect(obj)
          break
        case 'phone number':
          this.phoneStoreAndRedirect(obj)
          break
        default:
          //email
          this.emailStoreAndRedirect(obj)
          break
      }
    },
  },
  computed: {
    ...mapGetters({
      demograph: 'user/demograph',
      iCanSee: 'user/iCanSee',
      individualCommunicationsData: 'membership/individualCommunicationsData',
      individualProfile: 'membership/individualProfile',
      officerToolbarSelected: 'user/officerToolbarSelected',
      okToEditProfile: 'user/okToEditProfile',
      prefCulture: 'user/userPreferredCulture',
      userId: 'user/userId',
      userLogin: 'user/userLogin',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
    }),
    categoryDeleted() {
      // Returns text like: 'The `categoryName` was deleted.'
      return `${this.translations.tcThe} ${this.payload.category} ${this.translations.tcWasDeleted}`
    },
    categoryNotDeleted() {
      // Returns text like: 'An error occurred... The `categoryName` was not deleted.'
      const { tcErrorDuringSave, tcThe, tcWasNotDeleted } = this.translations
      return `${tcErrorDuringSave} ${tcThe} ${this.payload.category} ${tcWasNotDeleted}`
    },
    pageCode() {
      let newCode = ''
      switch (this.$route.name) {
        case 'prospect-communications':
          newCode = 'prospect'
          break
        case 'nonmember-communications':
          newCode = 'nonmember'
          break
        default:
          // member
          newCode = 'member'
          break
      }
      return newCode
    },
    title() {
      return this.createTitle(this.pageCode)
    },
    backLink() {
      return {
        member: '/programs/mb/member-profile',
        prospect: '/programs/mb/prospect-profile',
        nonmember: '/programs/mb/non-member-profile-mb',
      }[this.pageCode || 'member']
    },
  },
  async created() {
    try {
      if (!this.userSelectedIndividualKey) {
        this.$router.push('/programs/mb/membership/')
      }
      this.setLoadingStatus(true)
      await Promise.all([
        await this.getViewTranslations(),
        await this.getComponentTranslations('back-jump', 'quick-jump', 'service-card'),
        this.getComponentTranslations(
          'common.address-types',
          'common.church-service-type',
          'common.days',
          'common.email-types',
          'common.fax-types',
          'common.months-abbreviations',
          'common.phone-types'
        ),
        this.pageLoad(),
      ]).then((results) => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common },
        }
        this.$set(this.translations, 'components', translatedText)
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  components: {
    BackJump: BackJump,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    ServiceCard: ServiceCard,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.service-times-presentations {
  header h1 {
    padding-bottom: 80px;

    @include breakpoint(sm) {
      padding-bottom: 0;
    }
  }

  h2 {
    margin: 0;
    color: #000000;
    font-family: $DIN;
    font-size: 42px;
    letter-spacing: 1.4px;
    line-height: 44px;
    text-transform: uppercase;
    display: flex;

    .i-tooltip {
      position: relative;
      top: -5px;
      margin-left: 10px;
      line-height: 0;

      svg path {
        fill: #000;
      }
    }
  }

  section {
    margin-bottom: 40px;

    .p-4 {
      padding: 40px 30px 20px 30px !important;

      @include breakpoint(sm) {
        padding: 20px 24px 20px 24px !important;
      }
    }

    header {
      display: flex;

      h2 {
        flex: 1 1 auto;

        @include breakpoint(sm) {
          margin-bottom: 30px;
        }
      }

      .toggle-arrow {
        align-self: flex-start;
        margin-top: 5px;
      }
    }

    @include breakpoint(sm) {
      .btn {
        width: 100%;
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  .service-times,
  .pres-dates {
    margin-top: 35px;
  }

  .pres-dates {
    .date-card {
      width: calc(33.333% - 15px);
      margin-right: 15px;

      @include breakpoint(sm) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.event {
  margin-bottom: 0;
  border: 1px solid #e9e9e9;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

  h3 {
    color: $teal-800;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 22px;
  }
}
</style>
